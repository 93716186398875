import React from "react";
import Layout from "../components/layout-contents";
import Container from "../components/container";

export default () => (
  <Layout>
    <Container>
      <h1 className='page_title'>
        <img className='page_title_image'
             src="/images/home/logo_about.png"
             alt="about"
        /></h1>

      <div className="about-image" style={{ maxWidth: '80%', margin: '0 auto'}}>
        <img src="/images/about/mostro.png" alt="about"/>
      </div>
      <div className="about-content">
        <p>COdA started in 2017. It is an internationally distributed DIY fanzine, music label and text publisher
          made for and by artists, musicians and writers.</p>
        <p>COdA staggered to life as a free fanzine containing sinister gig reviews, dystopian comics, interviews and
          surreal writings. By 2018, COdA was producing records, prints, concerts, novels and comics, all through
          collective action and DIY craft by artists scattered about the globe.</p>
        <p>Instead of focusing on a specific style or genre, COdA tries to make connections within and throughout
          the underground’s alternative, weird and experimental cultures.</p>
        <p>COdA is mainly active in Europe organizing events in mountain tops, warehouses, beaches, tunnels,
          river beds, palladian villas, squats, independent venues, abandoned buildings – wherever Temporary
          Autonomous Zones effloresce.</p>
        <p>A COdA show is a radical situationist eruption, a gathering of people, art and sound beyond words: punk,
          noise, tekno, grindcore, industrial, improv jazz, rocknroll, fluxus, powerviolence, field recordings, weird
          folk, ambient – especially wherever the environment itself plays an important role in making and listening.
         </p>
        <p>COdA supports formlessness, extreme culture and autonomous bodies.</p>
      </div>
    </Container>
  </Layout>
)